import React from 'react';
import "./header.css";
import CTA from "./CTA";
import ME from '../../assets/jk-pfp.png';
import HeaderSocials from "./HeaderSocials";
import {AiOutlineArrowRight} from "react-icons/ai";




const Header = () => {

  return (
    <header id="home">
      <div className="container header__container">
        <h1 className='header__name'>Jaskaran Singh</h1>
        <h4 className="text-light header__title">Business Development Executive | Expertise in Marketing, Business Modeling & Team Management | MBA in Marketing</h4>
        <h5 className="text-light header__text">
          Dynamic Business Development Executive with 3+ years in the ed-tech sector, holding an MBA in Marketing. I have a proven track record in driving growth and fostering innovation. Let's elevate your business together. Currently based in Mumbai, India.
        </h5>
        <div className="me">
          <img src={ME} alt="me" />
        </div>
        <CTA />
        <HeaderSocials />

        <a href="#contact" className="scroll__down">
          <AiOutlineArrowRight />
        </a>
      </div>
    </header>
  );
};

export default Header;
