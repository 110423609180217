import React from 'react'
import './skills.css'
import {SiMicrosoftoffice} from 'react-icons/si'
import { MdSlideshow } from "react-icons/md";
import {FaBriefcase, FaUsers, FaChartLine} from 'react-icons/fa'
import { RiStackLine } from "react-icons/ri";
import { FaRupeeSign } from "react-icons/fa";
import { BiNetworkChart } from "react-icons/bi";
import SkillBlock from './SkillBlock'

const Skills = () => {
  return (
    <section id='skills'>
      <h2>Skills</h2>

      <div className='container skills__container'>
        <div className='skills__frontend'>
          <div className="skills__content">
            <SkillBlock skillTitle='Marketing & Sales' skillLevel='Advanced' skillIcon={<FaRupeeSign />} />
            <SkillBlock skillTitle='Business Development' skillLevel='Advanced' skillIcon={<FaBriefcase />} />
            <SkillBlock skillTitle='Team Management' skillLevel='Advanced' skillIcon={<FaUsers />} />
            <SkillBlock skillTitle='Microsoft Office' skillLevel='Intermediate' skillIcon={<SiMicrosoftoffice />} />
            <SkillBlock skillTitle='Presentation Skills' skillLevel='Intermediate' skillIcon={<MdSlideshow />} />
            <SkillBlock skillTitle='Business Modeling' skillLevel='Intermediate' skillIcon={<RiStackLine />} />
            <SkillBlock skillTitle='Market Analysis' skillLevel='Advanced' skillIcon={<FaChartLine />} />
            <SkillBlock skillTitle='Strategic Planning' skillLevel='Intermediate' skillIcon={<BiNetworkChart />} />
          </div> 
        </div>
      </div>
    
    </section>
  )
}

export default Skills