import Header from './components/header/Header'
import Nav from './components/nav/Nav'
import Experience from './components/experience/Experience'
import Education from './components/education/Education'
import Skills from './components/skills/Skills'
import Projects from './components/projects/Projects'
// import Services from './components/services/Services'
// import Portfolio from './components/portfolio/Portfolio'
// import Testimonials from './components/testimonials/Testimonials'
import Contact from './components/contact/Contact'
import Footer from './components/footer/Footer'

const App = () => {

  return (
    <>
      <Header />
      <Nav />
      <Experience />
      <Projects />
      <Education />
      <Skills />
      {/* <Portfolio />
      <Services />
      <Testimonials /> */}
      <Contact />
      <Footer />
    </>
  )
}

export default App;