import React from 'react'
import './skillblock.css'
import { IconContext } from 'react-icons';
import PropTypes from 'prop-types';


const SkillBlock = ({skillTitle, skillLevel, skillIcon}) => {
    return (
        <div className='skills__container'>
            <IconContext.Provider value={{size: '3em'}}>
                {skillIcon}
            </IconContext.Provider>
            <h4>{skillTitle}</h4>
            <p>{skillLevel}</p>
        </div>
    )
}

SkillBlock.propTypes = {
    skillTitle: PropTypes.string.isRequired,
    skillLevel: PropTypes.string.isRequired,
    skillIcon: PropTypes.element.isRequired
}

export default SkillBlock   