import React from 'react'
import './education.css'
import GnimsLogo from '../../assets/gnims-logo.png';

const Education = () => {
  return (
    <section id='education'>
      <h2>Education</h2>

      <div className='container education__container'>
        <div className="education__cards">
          <article className="education__card">
            <div className='education__img__container'>
              <img src={GnimsLogo} alt="uoe-logo" />
            </div>
            <div className='education__data__container'>
              <div className='education__data'>
                <div className='education__titles'>
                  <h3>Master in Management Studies</h3>
                  <h3 className='text-light'>Guru Nanak Institute for Management Studies</h3>
                  <h3 className='text-light'>Mumbai, India</h3>
                </div>
                <div className='education__duration'>
                  <h3>2018 - 2020</h3>
                </div>
              </div>

              {/* <div className='education__description'>
                Developed and managed a diverse portfolio of over 15 applications and web tools catering to both internal operations and customer-facing needs.
              </div> */}

            </div>
          </article>
        </div>
      </div>

    </section>
  )
}

export default Education