import React from 'react'
import { BsLinkedin } from "react-icons/bs";
import './footer.css'


const Footer = () => {
  return (
    <footer>
      <a href='#home' className='footer__logo'>Jaskaran Singh</a>
      <ul className='permalinks'>
        <li><a href='#home'>Home</a></li>
        <li><a href='#contact'>Contact</a></li>
      </ul>

      <div className='footer__socials'>
        <a href="https://www.linkedin.com/in/jaskaran-singh-572142129/" target="_blank" rel="noopener noreferrer">
          <BsLinkedin />
        </a>

      </div>

    </footer>
  )
}

export default Footer