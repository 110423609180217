import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { SiMaildotru } from "react-icons/si";


const HeaderSocials = () => {
  return (
    <div className="header__socials">
      <a href="https://www.linkedin.com/in/jaskaran-singh-572142129/" target="_blank" rel="noopener noreferrer">
        <BsLinkedin />
      </a>
      <a href="mailto:juskaran97@gmail.com" target="_blank" rel="noopener noreferrer">
        <SiMaildotru />
      </a>

    </div>
  );
};

export default HeaderSocials;
