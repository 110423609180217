import React from 'react'
import './projects.css'
import KhalsaLogo from '../../assets/gnims-logo.png';
import AarhusLogo from '../../assets/aarhus-university-logo-vector.svg';
import VedantaLogo from '../../assets/vedanta-logo.png';

const Projects = () => {
  return (
    <section id='project'>
      <h2>Projects</h2>

      <div className='container project__container'>
        <div className="project__cards">
          <article className="project__card">
            <div className='project__img__container'>
              {/* iologo with size and color */}
              <img src={KhalsaLogo} alt="gnims-logo" />
            </div>
            <div className='project__data__container'>
              <div className='project__data'>
                <div className='project__titles'>
                  <h3>Sales productivity benchmarking for ACC cement</h3>
                  <h3 className='text-light'>Associated with Guru Nanak Institute of Management Studies</h3>
                </div>
                <div className='project__duration'>
                  <h3>January 2020</h3>
                </div>
              </div>

              <div className="project__description">
                Sales productivity benchmarking Outline strategies which can be easily implemented by ACC to increase the productivity of sales force after a comparative study of competition.
              </div>

            </div>
          </article>

          <article className="project__card">
            <div className='project__img__container'>
              {/* laravel logo with size and laravel red color */}
              <img src={AarhusLogo} alt="aarhus-logo" />
            </div>
            <div className='project__data__container'>
              <div className='project__data'>
                <div className='project__titles'>
                  <h3>Multi Business Model Innovation</h3>
                  <h3 className='text-light'>Associated with Aarhus University</h3>
                </div>

                <div className='project__duration'>
                  <h3>June 2019 - July 2019</h3>
                </div>
              </div>

              <div className="project__description">
                Created and executed a comprehensive business model for Midtjylland Airport (Karup) in Denmark, focusing on optimizing revenue streams, enhancing operational efficiency, and fostering sustainable growth. Implemented strategies to attract new partnerships and streamline operations, resulting in notable improvements in profitability and market positioning.
              </div>

            </div>
          </article>

          <article className="project__card">
            <div className='project__img__container'>
              {/* laravel logo with size and laravel red color */}
              <img src={VedantaLogo} alt="vedanta-logo" />
            </div>
            <div className='project__data__container'>
              <div className='project__data'>
                <div className='project__titles'>
                  <h3>Rightsizing aluminum division of Vedanta</h3>
                  <h3 className='text-light'>Associated with Guru Nanak Institute of management Studies</h3>
                </div>

                <div className='project__duration'>
                  <h3>November 2018 - December 2018</h3>
                </div>
              </div>

              <div className="project__description">
                Developed and implemented a strategic approach to right-size Vedanta's Jharsuguda plant, conducting thorough analysis to identify areas for optimization and cost reduction. Collaborated with cross-functional teams to streamline operations, enhance productivity, and align resource allocation with organizational goals, resulting in significant improvements in efficiency and profitability.
              </div>

            </div>
          </article>

        </div>
      </div>

    </section>
  )
}

export default Projects