import React from 'react'
import './experience.css'
import finLogo from '../../assets/21k-logo.png';
import aarhLogo from '../../assets/aarhus-university-logo-vector.svg';
import futureLogo from '../../assets/future-generali-logo.png';

const Experience = () => {
  return (
    <section id='experience'>
      <h2>Work Experience</h2>

      <div className='container experience__container'>
        <div className="experience__cards">
          <article className="experience__card">
            <div className='experience__img__container'>
              <img src={finLogo} alt="21k-logo" />
            </div>
            <div className='experience__data__container'>
              <div className='experience__data'>
                <div className='experience__titles'>
                  <h3>Business Development Manager</h3>
                  <h3 className='text-light'>21K School</h3>
                  <h3 className='text-light'>Bangalore, India</h3>
                  {/* <h4 className='text-light'>Mumbai, India</h4> */}
                </div>
                <div className='experience__duration'>
                  <h3>March 2021 - Present</h3>
                </div>
              </div>

              <div className='experience__description'>
                
              </div>

            </div>
          </article>
          <article className="experience__card">
            <div className='experience__img__container'>
              <img src={aarhLogo} alt="aarhus-logo" />
            </div>
            <div className='experience__data__container'>
              <div className='experience__data'>
                <div className='experience__titles'>
                  <h3>Mobility Participant Innovatorium Herning A/S Denmark</h3>
                  <h3 className='text-light'>Aarhus University</h3>
                  <h3 className='text-light'>Herning, Denmark</h3>
                </div>
                <div className='experience__duration'>
                  <h3>June 2019 - July 2019</h3>
                </div>
              </div>

              <div className='experience__description'>
              </div>

            </div>
          </article>

          <article className="experience__card">
            <div className='experience__img__container'>
              <img src={futureLogo} alt="future-logo" />
            </div>
            <div className='experience__data__container'>
              <div className='experience__data'>
                <div className='experience__titles'>
                  <h3>Marketing Intern</h3>
                  <h3 className='text-light'>Future Generali India Insurance Company Limited</h3>
                  <h3 className='text-light'>Mumbai, India</h3>
                </div>
                <div className='experience__duration'>
                  <h3>May 2019 - June 2019</h3>
                </div>
              </div>

              <div className='experience__description'>
              </div>

            </div>
          </article>
        </div>
      </div>

    </section>
  )
}

export default Experience